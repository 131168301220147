<template>
  <v-container class="pa-0">
    <card-view
      v-for="item of eipData"
      :key="item.id"
      :is-editable="isEditAllowed && !item.is_published"
      :is-deleteable="isDeleteAllowed && !item.is_published"
      :details="item.details"
      :delete-title="'Delete Equity Incentive Plan'"
      :delete-message="'Are you sure you want to delete this equity incentive plan?'"
      :delete-note="'Note: plans cannot be deleted if they are linked to securities'"
      :reload="reload"
      @edit="routeToEdit(item)"
      @delete="removeItem(item)"
    >
      <template v-slot:card-title>
        {{ item.name }}
      </template>
      <template v-slot:card-caption-title>
        Share available for issuance:
      </template>
      <template v-slot:card-caption-data>
        {{ $number(item.equity_available_for_issuance) }}
      </template>
      <template v-slot:created-on-date>
        {{ $date(item.inserted_at) }}
      </template>
      <template v-slot:sub-cards>
        <v-row>
          <v-col>
            <span class="text-capitalize">
              Shares reserved under plan:
            </span>
            <span class="trsText--text text-body-3 font-weight-medium text-capitalize">
              {{ $number(item.authorized_shares) }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="box-section py-5 px-4">
              <div class="trsText--text text-h4 font-weight-medium text--light-1 pl-1">
                Options
              </div>
              <v-simple-table class="base-bg calc-table mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        id="options-status"
                        class="text-left trsText--text text-body-2 text--lighten-1 font-weight-medium"
                      >
                        Status
                      </th>
                      <th
                        id="option-no-shares"
                        class="text-center trsText--text text-body-2 text--lighten-1 font-weight-medium"
                      >
                        No. of shares
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="trsText--text text-body-1 text--lighten-1">
                        Granted
                      </td>
                      <td class="text-center trsText--text text-body-1">
                        {{ $number(item.option_numbers.granted) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="trsText--text text-body-1 text--lighten-1">
                        Exercised
                      </td>
                      <td class="text-center trsText--text text-body-1">
                        {{ $number(item.option_numbers.exercised) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="trsText--text text-body-1 text--lighten-1">
                        Forfeited
                      </td>
                      <td class="text-center trsText--text text-body-1">
                        {{ $number(item.option_numbers.forfeited) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="trsText--text text-body-1 text--lighten-1">
                        Expired
                      </td>
                      <td class="text-center trsText--text text-body-1">
                        {{ $number(item.option_numbers.expired) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="trsText--text text-body-1 text--lighten-1">
                        Outstanding
                      </td>
                      <td class="text-center trsText--text text-body-1">
                        {{ $number(item.option_numbers.outstanding) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
          <v-col>
            <div class="box-section py-5 px-4">
              <div class="trsText--text text-h4 font-weight-medium text--light-1 pl-1">
                Restricted shares
              </div>
              <v-simple-table class="base-bg calc-table mt-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        id="options-status"
                        class="text-left trsText--text text-body-2 text--lighten-1 font-weight-medium"
                      >
                        Status
                      </th>
                      <th
                        id="option-no-shares"
                        class="text-center trsText--text text-body-2 text--lighten-1 font-weight-medium"
                      >
                        No. of shares
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="trsText--text text-body-1 text--lighten-1">
                        Granted
                      </td>
                      <td class="text-center trsText--text text-body-1">
                        {{ $number(item.restricted_share_numbers.granted) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="trsText--text text-body-1 text--lighten-1">
                        Forfeited Returned
                      </td>
                      <td class="text-center trsText--text text-body-1">
                        {{ $number(item.restricted_share_numbers.forfeited_returned) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="trsText--text text-body-1 text--lighten-1">
                        Forfeited but not returned to the pool
                      </td>
                      <td class="text-center trsText--text text-body-1">
                        {{ $number(item.restricted_share_numbers.forfeited_not_returned) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="trsText--text text-body-1 text--lighten-1">
                        Outstanding
                      </td>
                      <td class="text-center trsText--text text-body-1">
                        {{ $number(item.restricted_share_numbers.outstanding) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
          <v-col>
            <div class="box-section py-5 px-4">
              <div class="trsText--text text-h4 font-weight-medium text--light-1 pl-1">
                Grace periods
              </div>
              <v-simple-table class="base-bg mt-2">
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(obj, i) of CONSTS.GRACE_PERIODS"
                      :key="i"
                    >
                      <td class="trsText--text text-body-1 text--lighten-1">
                        {{ i }}
                      </td>
                      <td class=" trsText--text text-body-1">
                        {{ getGracePerids(item, obj) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-divider />
          </v-col>
          <v-col
            cols="12"
          >
            <div>
              <label class="float-left">
                Plan Amendments
              </label>
              <common-trs-btn
                v-feature="CONSTS.CAPTABLE_FEATURES.AMEND_EIP"
                type="secondary"
                small
                class="white--text ml-auto float-right"
                text
                @click="openModal(item)"
              >
                <v-icon class="mr-2">
                  mdi-plus-circle
                </v-icon>
                Amend Plan
              </common-trs-btn>
            </div>
          </v-col>
          <v-col
            v-if="item.plan_amendments && item.plan_amendments.length > 0"
            cols="12"
          >
            <common-trs-data-table
              :headers="amendTableHeaders"
              :items="item.plan_amendments"
              item-key=""
              :loading="loading"
              hide-default-footer
            >
              <template v-slot:[`item.amended_on`]="{ item }">
                <span>
                  {{ $date(item.amended_on) }}
                </span>
              </template>
              <template v-slot:[`item.shares_reserved`]="{ item }">
                <span>
                  {{ $number(item.shares_reserved) }}
                </span>
              </template>
              <template v-slot:[`item.board_adopted_on`]="{ item }">
                <span>
                  {{ $date(item.board_adopted_on) }}
                </span>
              </template>
              <template v-slot:[`item.shareholders_approved_on`]="{ item }">
                <span>
                  {{ $date(item.shareholders_approved_on) }}
                </span>
              </template>
            </common-trs-data-table>
          </v-col>
          <v-col
            v-else
            cols="12"
            class="trsText--text font-weight-light text-body-2 text--lighten-1 py-0"
          >
            No amendments
          </v-col>
        </v-row>
      </template>
    </card-view>
    <amend-plan-modal
      v-model="internalDialog"
      :eip-details="eipDetails"
      :loading="loading"
      :is-submit="isSubmit"
      @save="save"
      @updateList="reloadNotes"
    />
  </v-container>
</template>
<script>
  import CardView from '@/components/common/captable/components/CardView'
  import AmendPlanModal from './AmendPlanModal'
  import * as captableService from '@/components/common/captable/services/captable'
  import { getFeaturePermission } from '@/services/utils'

  export default {
    name: 'EipList',

    components: {
      CardView,
      AmendPlanModal,
    },

    props: {
      eipData: {
        type: Array,
        required: true,
      },
      shareTermList: {
        type: Array,
        required: true,
      },
    },

    data: () => ({
      internalDialog: false,
      loading: false,
      isSubmit: false,
      amendTableHeaders: [
        { text: 'Amendment date', value: 'amended_on', sortable: true },
        { text: 'Shares reserved', value: 'shares_reserved', sortable: true },
        { text: 'Board adoption date', value: 'board_adopted_on', sortable: true },
        { text: 'Holder approval date', value: 'shareholders_approved_on', sortable: true },
      ],
      eipDetails: {},
      reload: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      currentRoles () {
        return this.$store.getters['auth/roles']
      },
      isEditAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY_TERM, this.currentRoles)
      },
      isDeleteAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY_TERM, this.currentRoles)
      },
    },

    watch: {
      eipData: {
        handler (val) {
          if (val) {
            this.updateSummaryData(val)
          }
        },
        immediate: true,
      },
    },

    methods: {
      routeToEdit (item) {
        const route = { name: 'EditEIP', query: { id: item.id } }
        this.$router.push(route)
      },
      getGracePerids (item, key) {
        const data = item.grace_periods.find((obj) => { return obj.type === key })
        return data ? data.duration : 0
      },
      async removeItem (itemData) {
        try {
          this.loading = true
          await captableService.deleteEipById(this.captableId, itemData.id)
          this.loading = false
          this.$emit('reload')
          this.$store.dispatch('app/message', { text: 'Equity incentive plan deleted successfully.' })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry,this equity incentive plan cannot be deleted.Please unlink any securities associated with this plan',
          })
        }
      },
      async save (data, id) {
        this.loading = true
        try {
          await captableService.amendPlan(this.captableId, id, data)
          this.$emit('reload')
          this.$store.dispatch('app/message', { text: 'Plan amended successfully.' })
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry, unable to amend the plan. Please try again later.',
          })
        }
        this.loading = false
        this.internalDialog = false
        this.isSubmit = true
        setTimeout(() => {
          this.isSubmit = false
        })
      },
      updateSummaryData (data) {
        data.forEach((item) => {
          item.details = [{
            id: item.id,
            type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.EQUITY_INCENTIVE_PLANS,
          }]
          const linkedShareTermData = this.getLinkedShareTerm(item)
          if (linkedShareTermData && linkedShareTermData.length > 0) {
            item.details[0].label = 'Linked Share Term'
            item.details[0].data = linkedShareTermData
          }
        })
      },
      getLinkedShareTerm (item) {
        const linkedShareTerm = this.shareTermList.find(s => s.id === item.share_terms_id)
        const itemDetails = []
        if (linkedShareTerm) {
          const obj = {
            'Share Term Name': linkedShareTerm.name,
            'Board Approval Date': this.$date(linkedShareTerm.board_approved_on),
          }

          const keys = Object.keys(obj)
          keys.forEach((key) => {
            itemDetails.push({
              title: key,
              value: obj[key],
            })
          })
        }
        return itemDetails
      },
      openModal (item) {
        this.eipDetails = item
        this.internalDialog = true
      },
      reloadNotes () {
        this.reload = true
        setTimeout(() => {
          this.reload = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box-section {
    background: var(--v-accent-base);
    border-radius: 4px;
    min-height: 365px;
  }
  .base-bg {
    background: var(--v-accent-base);
    tr {
      th, td {
        border: none !important;
        padding: 5px !important;
      }
    }
  }
  .calc-table {
    tr:last-child {
      td {
        border-top: thin solid rgba(0, 0, 0, 0.12) !important;
      }
    }
  }
</style>
