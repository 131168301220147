<template>
  <v-dialog
    v-model="internalDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="700"
    scrollable
    v-bind="$attrs"
    :retain-focus="false"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          Amend Equity Incentive Plan
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <amend-eip-form
          :eip-details="eipDetails"
          :loading="loading"
          :is-submit="isSubmit"
          :reset-data="!internalDialog"
          @close="close"
          @save="save"
          @updateList="$emit('updateList')"
        />
      </common-trs-modal-card-text>
    </common-trs-modal-card>
  </v-dialog>
</template>

<script>
  import AmendEipForm from '@/components/common/captable/components/forms/AmendEipForm'

  export default {
    name: 'AmendPlanModal',

    components: {
      AmendEipForm,
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      eipDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      internalDialog: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    methods: {
      close () {
        this.internalDialog = false
      },
      save (data, itemId) {
        this.$emit('save', data, itemId)
      },
    },
  }
</script>
