<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
      class="ml-auto"
    >
      <v-row>
        <v-col
          cols="12"
          class="trsText--text font-weight-medium py-0 text-h4"
        >
          Shares Reserved Under Plan
        </v-col>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            :name="'Number of shares'"
            :rules="shareRule"
          >
            <common-input-currency-input
              v-model="formData.authorized_shares"
              label="Number of Shares"
              color="primary"
              :error-messages="errors"
              :hint="sharesHint"
              persistent-hint
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="6"
          class="pb-0"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :name="'Board approval date'"
          >
            <input-template-date
              v-model="boardApprovalDate"
              :mask="'##/##/####'"
              :error-messages="errors"
              :input-templ="{label: 'Board approval date', mandatory: true}"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="6"
          class="pb-0"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :name="'Holder approval date'"
          >
            <input-template-date
              v-model="holderApprovalDate"
              :mask="'##/##/####'"
              :error-messages="errors"
              :input-templ="{label: 'Holder approval date', mandatory: true}"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <notes-and-documents-form
            :id="eipDetails.id"
            :is-submit="isSubmit"
            :type="CONSTS.NOTES_DOCUMENT_API_TYPE.EQUITY_INCENTIVE_PLANS"
            @navToList="updateList"
          />
        </v-col>
      </v-row>
      <!-- Form Buttons Section -->
      <v-row>
        <v-col>
          <v-divider />
          <div class="mt-2 d-flex justify-space-between">
            <common-trs-btn
              type="secondary"
              medium
              class="mr-2"
              text
              @click="$emit('close')"
            >
              Cancel
            </common-trs-btn>
            <common-trs-btn
              type="primary"
              medium
              class="white--text"
              text
              :disabled="!valid"
              :loading="loading"
              @click="save"
            >
              Save
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>

<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import NotesAndDocumentsForm from '@/components/common/captable/components/forms/NotesAndDocumentsForm'

  export default {
    name: 'AmendEipForm',

    components: {
      InputTemplateDate,
      NotesAndDocumentsForm,
    },

    props: {
      eipDetails: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
      resetData: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      formData: {},
      boardApprovalDate: '',
      holderApprovalDate: '',
    }),

    computed: {
      sharesHint () {
        if (this.eipDetails) {
          return (this.eipDetails.authorized_shares).toString().concat(` currently reserved under plan, and ${this.eipDetails.equity_available_for_issuance} available for issuance`)
        } else {
          return ''
        }
      },
      shareRule () {
        return { required: true, min_value: (this.eipDetails.authorized_shares + 1) }
      },
    },

    watch: {
      boardApprovalDate: {
        handler (val) {
          if (val) {
            this.formData.board_adopted_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      holderApprovalDate: {
        handler (val) {
          if (val) {
            this.formData.shareholders_approved_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      resetData: {
        handler (val) {
          if (val) {
            this.resetForm()
          }
        },
        immediate: true,
      },
    },

    methods: {
      save () {
        this.$emit('save', this.formData, this.eipDetails.id)
        this.resetForm()
      },
      resetForm () {
        this.formData = {}
        this.boardApprovalDate = ''
        this.holderApprovalDate = ''
        this.$refs.observer.reset()
      },
      updateList () {
        this.$emit('updateList')
        this.$emit('close')
      },
    },
  }
</script>
