<template>
  <common-trs-page-container :padding="0">
    <div class="text-right">
      <common-trs-btn
        v-if="eipData.length>0"
        v-feature="CONSTS.CAPTABLE_FEATURES.CREATE_SECURITY_TERM"
        type="primary"
        large
        class="white--text ml-auto mt-3"
        text
        :to="newEipRoute"
      >
        <v-icon class="mr-2">
          mdi-plus-circle
        </v-icon>
        New Equity Incentive Plan
      </common-trs-btn>
    </div>
    <common-page-loader
      v-if="loading"
    />
    <eip-list
      :eip-data="eipData"
      :share-term-list="shareTermList"
      @reload="loadData"
    />
    <no-data-msg
      v-if="eipData.length===0 && !loading"
      :route-detail="isCreateAllowed ? newEipRoute : null"
    >
      <template v-slot:msg>
        No equity incentive plan found.
      </template>
      <template v-slot:action-btn-label>
        Create an Equity Incentive Plan
      </template>
      <template v-slot:tooltip-msg>
        Create New Equity Incentive Plan
      </template>
    </no-data-msg>
  </common-trs-page-container>
</template>
<script>
  import NoDataMsg from '@/components/common/captable/components/NoDataMsg'
  import EipList from './components/List'
  import * as captableService from '@/components/common/captable/services/captable'
  import { getFeaturePermission } from '@/services/utils'

  export default {
    name: 'EquityIncentivePlanIndex',

    components: {
      NoDataMsg,
      EipList,
    },

    data: () => ({
      eipData: [],
      loading: false,
      newEipRoute: { name: 'NewEIP' },
      shareTermList: [],
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      currentRoles () {
        return this.$store.getters['auth/roles']
      },
      isCreateAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.CREATE_SECURITY_TERM, this.currentRoles)
      },
    },

    async mounted () {
      await this.loadShareTerms()
      await this.loadData()
    },

    methods: {
      async loadData () {
        try {
          this.loading = true
          const resp = await captableService.getEipList(this.captableId)
          if (resp && resp.data) {
            this.eipData = resp.data.equity_incentive_plans.sort((a, b) => {
              return new Date(b.updated_at) - new Date(a.updated_at)
            })
          }
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      async loadShareTerms () {
        try {
          const resp = await captableService.getShareTermList(this.captableId)
          if (resp && resp.data) {
            this.shareTermList = resp.data.share_terms
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data.',
          })
        }
      },
    },
  }
</script>
