var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",staticClass:"ml-auto",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-row',[_c('v-col',{staticClass:"trsText--text font-weight-medium py-0 text-h4",attrs:{"cols":"12"}},[_vm._v(" Shares Reserved Under Plan ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":'Number of shares',"rules":_vm.shareRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Number of Shares","color":"primary","error-messages":errors,"hint":_vm.sharesHint,"persistent-hint":""},model:{value:(_vm.formData.authorized_shares),callback:function ($$v) {_vm.$set(_vm.formData, "authorized_shares", $$v)},expression:"formData.authorized_shares"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":'Board approval date'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"input-templ":{label: 'Board approval date', mandatory: true},"clearable":""},model:{value:(_vm.boardApprovalDate),callback:function ($$v) {_vm.boardApprovalDate=$$v},expression:"boardApprovalDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":'Holder approval date'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"input-templ":{label: 'Holder approval date', mandatory: true},"clearable":""},model:{value:(_vm.holderApprovalDate),callback:function ($$v) {_vm.holderApprovalDate=$$v},expression:"holderApprovalDate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.eipDetails.id,"is-submit":_vm.isSubmit,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.EQUITY_INCENTIVE_PLANS},on:{"navToList":_vm.updateList}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider'),_c('div',{staticClass:"mt-2 d-flex justify-space-between"},[_c('common-trs-btn',{staticClass:"mr-2",attrs:{"type":"secondary","medium":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('common-trs-btn',{staticClass:"white--text",attrs:{"type":"primary","medium":"","text":"","disabled":!valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }